import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-full-logo',
  templateUrl: './full-logo.component.html',
  styleUrls: ['./full-logo.component.scss']
})
export class FullLogoComponent {
  @Input() linkActive = true;
}
